/**
 * 接口域名的管理
 */
const host = process.env.VUE_APP_API_HOST
const base = {
    sq: host + '/v1/school_api',
    bd: host + '/school_api/',
	imgUrl: process.env.VUE_APP_ASSET_HOST
	// sq: 'http://hzyzdj.zhiyunapi.com/v1/school_api',
	// bd: 'http://hzyzdj.zhiyunapi.com/school_api/',
	// imgUrl: 'http://hzyzdj.zhiyunapi.com'
}

export default base;
