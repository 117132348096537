import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const station = {
	// 岗位列表 
	getJobList(params){
		return axios.get(`${base.sq}/job/index`, {
			params:params
		});
	},
	// 获取年级默认学科
	getGradeDiscipline(params){
		return axios.get(`${base.sq}/grade/get_grade_discipline`, {
			params:params
		});
	},
	// 添加岗位
	addJob(params){
		return axios.post(`${base.sq}/job/add`, qs.stringify(params));
	},
	// 编辑岗位
	editJob(params){
		return axios.post(`${base.sq}/job/edit`, qs.stringify(params));
	},
	// 删除岗位
	delJob(params){
		return axios.post(`${base.sq}/job/del`, qs.stringify(params));
	},
	// 获取岗位配置数据
	getJobConfig(params){
		return axios.get(`${base.sq}/job/setting`, {
			params:params
		});
	},
	// 获取学科列表
	getXuekeList(params){
		return axios.get(`${base.sq}/job_discipline/index`, {
			params:params
		});
	},
	// 岗位老师列表
	getLaoshiList(params){
		return axios.get(`${base.sq}/job_discipline_data/index`, {
			params:params
		});
	},
	// 添加岗位学科
	addDiscipline(id,params){
		return axios.post(`${base.sq}/job_discipline/add?id=${id}`, {
			data:params
		});
	},
	// 保存岗位班级
	saveDiscipline(id,params){
		return axios.post(`${base.sq}/job/setting?id=${id}`, params);
	},
	// 课时统计
	statistics(params){
		return axios.get(`${base.sq}/course_config/statistics`, {
			params:params
		});
	},
	
}

export default station;