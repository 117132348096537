import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		token:'',
		nickname:'',
		avatar:'',
		meunIndex:'',
		meunList:[]
	},
    mutations: {
        settoken (state, payload) {
        	state.token = payload;
            window.localStorage.setItem("token",state.token);
        },
		nickname (state, payload) {
			state.nickname = payload;
		    window.localStorage.setItem("nickname",state.nickname);
		},
		avatar (state, payload) {
			state.avatar = payload;
		    window.localStorage.setItem("avatar",state.avatar);
		},
		meunIndex(state,payload){
			state.meunIndex = payload;
		},
		meunList(state,arr){
			console.log(arr)
			state.meunList = arr;
		}
	},
	actions: {

	},
	getters: {

	}
})

export default store