<template>
	<div id="app"><router-view /></div>
</template>

<script>
export default {
	name: 'App'
};
</script>

<style lang="scss">
.el-tooltip__popper{
	max-width: 1200px;
}
#app {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 0.729vw;
}
div {
	font-size: 0.729vw;
}
body {
	margin: 0;
}
.icon-nan {
	color: #5dc0ff;
	font-size: 0.729vw;
}
.icon-nv {
	color: #eb5900;
	font-size: 0.729vw;
}
.mouse{
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

</style>
