import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const rule = {
	systemMsgpush(params) {
		return axios.get(`${base.sq}/system_msg/my_push`,{params:params});
	},
	// 我申请的活动列表    I
	myActivitList(params) {
		return axios.get(`${base.sq}/activities/my_list`,{params:params});
	},
	// 活动申请    
	addActivit(params) {
		return axios.post(`${base.sq}/activities/add`, qs.stringify(params));
	},
	// 删除活动申请
	delActivit(params) {
		return axios.post(`${base.sq}/activities/del`, qs.stringify(params));
	},
	// 我的请假申请
	myLeaveList(params) {
		return axios.get(`${base.sq}/leave/my_list`,{params:params});
	},
	leaveindex(params) {
		return axios.get(`${base.sq}/leave/index`,{params:params});
	},
	// 作废
	leaveDestroy(params) {
		return axios.get(`${base.sq}/leave/destroy`,{params:params});
	},
	// 请假申请
	addLeave(params) {
		return axios.post(`${base.sq}/leave/add`, qs.stringify(params));
	},
	// 删除活动申请
	delLeave(params) {
		return axios.post(`${base.sq}/leave/del`, qs.stringify(params));
	},
	// 活动我的审批（抄送）
	myProcess(params) {
		return axios.get(`${base.sq}/activities/my_process`,{params:params});
	},
	// 我的请假抄送、待审核列表
	getLeaveMyProcess(params){
		return axios.get(`${base.sq}/leave/my_process`,{params:params});
	},
	// 我的年级
	getmyGrade(params){
		return axios.get(`${base.sq}/teacher_archives/my_grade`,{params:params});
	},
	// 我的班级
	getMyClass(params){
		return axios.get(`${base.sq}/teacher_archives/my_class`,{params:params});
	},
	// 添加公开课
	addOpenClass(params){
		return axios.post(`${base.sq}/open_class/add`,qs.stringify(params));
	},
	// 我的公开课申请
	MyOpenClassList(params){
		return axios.get(`${base.sq}/open_class/my_list`,{params:params});
	},
	// 公开课列表
	OpenClassList(params){
		return axios.get(`${base.sq}/open_class/my_process`,{params:params});
	},
	// 申请报修
	addRepair(params){
		return axios.post(`${base.sq}/repair/add`,qs.stringify(params));
	},
	// 我申请的报修
	MyrepairList(params){
		return axios.get(`${base.sq}/repair/my_list`,{params:params});
	},
	// 报修列表
	repairList(params){
		return axios.get(`${base.sq}/repair/index`,{params:params});
	},
	myRepairProcess(params){
		return axios.get(`${base.sq}/repair/my_process`,{params:params});
	},
	repairdetails(params){
		return axios.get(`${base.sq}/repair/get`,{params:params});
	},
	auditRepair(params){
		return axios.get(`${base.sq}/repair/audit`,{params:params});
	},
	// 安全隐患申报
	addPotential(params){
		return axios.post(`${base.sq}/potential/add`,qs.stringify(params));
	},
	// 我申请的安全隐患列表
	myPotentialList(params){
		return axios.get(`${base.sq}/potential/my_list`,{params:params});
	},
	// 我申请的安全隐患列表
	myPotentialProcess(params){
		return axios.get(`${base.sq}/potential/my_process`,{params:params});
	},
	// 安全隐患列表
	PotentialList(params){
		return axios.get(`${base.sq}/potential/index`,{params:params});
	},
	// 公告列表
	systemMsgList(params){
		return axios.get(`${base.sq}/system_msg/my_list`,{params:params});
	},
	// 公告列表
	systemMsgindex(params){
		return axios.get(`${base.sq}/system_msg/index`,{params:params});
	},
	// 发布公告
	addSystemMsg(params){
		return axios.post(`${base.sq}/system_msg/add`,qs.stringify(params));
	},
	// 删除公告
	delSystemMsg(params){
		return axios.post(`${base.sq}/system_msg/my_del`,qs.stringify(params));
	},
	// 删除公告
	delSystemMyMsg(params){
		return axios.post(`${base.sq}/system_msg/del`,qs.stringify(params));
	},
	// 
	// 公告详情
	systemGet(params){
		return axios.get(`${base.sq}/system_msg/get`,{params:params});
	},
	// 读取消息、
	systemRead(params){
		return axios.get(`${base.sq}/system_msg/read`,{params:params});
	},
	//获取指定时间的排课
	getCourse(params){
		return axios.get(`${base.sq}/course_config/get_course`,{params:params});
	},
	// 我的申请
	getMyFlow(params){
		return axios.get(`${base.sq}/form/flow`,{params:params});
	},
	// 抄送、审核
	getduplicate(params){
		return axios.get(`${base.sq}/form/duplicate`,{params:params});
	},
	// 学生请假详情
	studentDetails(params){
		return axios.get(`${base.sq}/student_leave/get`,{params:params});
	},
	// 活动申请详情
	activitDetails(params){
		return axios.get(`${base.sq}/activities/get`,{params:params});
	},
	// 请假详情
	leaveDetails(params){
		return axios.get(`${base.sq}/leave/get`,{params:params});
	},
	// 校园报修
	repairDetails(params){
		return axios.get(`${base.sq}/repair/get`,{params:params});
	},
	// 公开课open_class
	openClassDetails(params){
		return axios.get(`${base.sq}/open_class/get`,{params:params});
	},
	// 安全隐患
	potentialDetails(params){
		return axios.get(`${base.sq}/potential/get`,{params:params});
	},
	// 资产管理
	propertyBorrowDetails(params){
		return axios.get(`${base.sq}/property_borrow/get`,{params:params});
	},
	// 学生请假审核
	studentAudit(params){
		return axios.post(`${base.sq}/student_leave/audit`,qs.stringify(params));
	},
	// 活动审核
	activitiesAudit(params){
		return axios.post(`${base.sq}/activities/audit`,qs.stringify(params));
	},
	// 请假审核
	leaveAudit(params){
		return axios.post(`${base.sq}/leave/audit`,qs.stringify(params));
	},
	// 校园审核
	repairAudit(params){
		return axios.post(`${base.sq}/repair/audit`,qs.stringify(params));
	},
	// 公开课审核
	openClassAudit(params){
		return axios.post(`${base.sq}/open_class/audit`,qs.stringify(params));
	},
	// 安全隐患审核
	potentialAudit(params){
		return axios.post(`${base.sq}/potential/audit`,qs.stringify(params));
	},
	// 资产管理审核
	propertyBorrowAudit(params){
		return axios.post(`${base.sq}/property_borrow/audit`,qs.stringify(params));
	},
	// 获取指定的调课老师
	getTeacherList(params){
		return axios.get(`${base.sq}/course_config/get_course_adjust_teacher_id`,{params:params});
	},
	// 公文流转详情
	getDocument(params){
		return axios.get(`${base.sq}/document/get`,{params:params});
	},
	// 会议列表
	meetingIndex(params){
		return axios.get(`${base.sq}/meeting/index`,{params:params});
	},
	// 会议列表
	meetingmyList(params){
		return axios.get(`${base.sq}/meeting/my_list`,{params:params});
	},
	// 会议列表
	getCourseConfigList(params){
		return axios.get(`${base.sq}/course_config_adjust/my_list`,{params:params});
	},
	//物品领用、我的申请
	getMyGoodsList(params){
		return axios.get(`${base.sq}/property_borrow/my_list`,{params:params});
	},
	// 审批、抄送
	getMyGoodsProcess(params){
		return axios.get(`${base.sq}/property_borrow/my_process`,{params:params});
	},
	// 我的出差申请
	getMyofficialList(params){
		return axios.get(`${base.sq}/teacher_official/my_list`,{params:params});
	},
	// 出差我的审批
	getMyofficialProcess(params){
		return axios.get(`${base.sq}/teacher_official/my_process`,{params:params});
	},
	// 出差详情
	getMyofficialGet(params){
		return axios.get(`${base.sq}/teacher_official/get`,{params:params});
	},
	// 我的申购
	getMaterialsList(params){
		return axios.get(`${base.sq}/materials/my_list`,{params:params});
	},
	// 申购审批
	getmaterialsProcess(params){
		return axios.get(`${base.sq}/materials/my_process`,{params:params});
	},
	// 申购详情
	getmaterialsGet(params){
		return axios.get(`${base.sq}/materials/get`,{params:params});
	},
	getscreenList(params){
		return axios.get(`${base.sq}/screen/my_list`,{params:params});
	},
	// 申购审批
	getscreenProcess(params){
		return axios.get(`${base.sq}/screen/my_process`,{params:params});
	},
	// 申购详情
	getscreenGet(params){
		return axios.get(`${base.sq}/screen/get`,{params:params});
	},
	// 公章使用申请-我的申请列表
	getchapterList(params){
		return axios.get(`${base.sq}/chapter/my_list`,{params:params});
	},
	// 公章使用申请-我的抄送列表
	getchapterProcess(params){
		return axios.get(`${base.sq}/chapter/my_process`,{params:params});
	},
	// 公章使用申请-详情
	getchapterDetail(params){
		return axios.get(`${base.sq}/chapter/get`,{params:params});
	},
	// 阶梯教室及录播室申请-我的申请列表
	getladderList(params){
		return axios.get(`${base.sq}/ladder/my_list`,{params:params});
	},
	// 阶梯教室及录播室申请-我的抄送列表
	getladderProcess(params){
		return axios.get(`${base.sq}/ladder/my_process`,{params:params});
	},
	// 阶梯教室及录播室申请-详情
	getladderDetail(params){
		return axios.get(`${base.sq}/ladder/get`,{params:params});
	},
	// 阶梯教室及录播室申请-列表
	getladderIndex(params){
		return axios.get(`${base.sq}/ladder/index`,{params:params});
	},
	// 阶梯教室及录播室申请-导出
	ladderExports(params){
		return axios.post(`${base.sq}/ladder/exports`,qs.stringify(params));
	},
	// 校车使用申请-我的申请列表
	getschoolbusList(params){
		return axios.get(`${base.sq}/schoolbus/my_list`,{params:params});
	},
	// 校车使用申请-我的抄送列表
	getschoolbusProcess(params){
		return axios.get(`${base.sq}/schoolbus/my_process`,{params:params});
	},
	// 校车使用申请-详情
	getschoolbusDetail(params){
		return axios.get(`${base.sq}/schoolbus/get`,{params:params});
	},
}

export default rule;