<template>
	<div id="login" class="u-f-item u-f-column">
		<img :src="img_url+'/assets/static/hlogo.png'" alt="" width="22%" style="margin-top: 150px;">
		<!-- 28 -->
		<div class="login-box">
			<el-input v-model="account" placeholder="请输入账号" style="margin-bottom: 30px;"></el-input>
			<el-input show-password v-model="password" placeholder="请输入密码" style="margin-bottom: 30px;"></el-input>
			<el-button type="primary" style="width: 100%;font-size: 20px;height: 50px;" @click="submit">登录</el-button>
			<div style="margin-top: 10px;color: #007AFF;cursor: pointer;" @click="upPad=true">忘记密码？</div>
		</div>
		<el-dialog title="找回密码" :visible.sync="upPad" width="500px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f-item input-box" v-if="Step==0||Step==3">
					<div class="input-lebal">
						<span>*</span>
						手机号码
					</div>
					<el-input v-model="mobile" placeholder="请输入手机号码"  style="width: 70%;"></el-input>
				</div>
				<div class="u-f-item input-box" v-if="Step==1">
					<div class="input-lebal">
						<span>*</span>
						找回方式
					</div>
					<el-radio v-model="radio" label="1">密保找回</el-radio>
					<el-radio v-model="radio" label="2">验证码找回</el-radio>
				</div>
				<div class="u-f-item input-box" v-if="Step==2">
					<div class="input-lebal">
						<span>*</span>
						问题1
					</div>
					<el-input v-model="problem1" placeholder="请输入问题1" style="width: 70%;" disabled></el-input>
				</div>
				<div class="u-f-item input-box" v-if="Step==2">
					<div class="input-lebal">
						<span>*</span>
						答案
					</div>
					<el-input v-model="answer1" placeholder="请输入答案" style="width: 70%;"></el-input>
				</div>
				<div class="u-f-item input-box" v-if="Step==2">
					<div class="input-lebal">
						<span>*</span>
						问题2
					</div>
					<el-input v-model="problem2" placeholder="请输入问题2" style="width: 70%;" disabled></el-input>
				</div>
				<div class="u-f-item input-box" v-if="Step==2">
					<div class="input-lebal">
						<span>*</span>
						答案
					</div>
					<el-input v-model="answer2" placeholder="请输入答案" style="width: 70%;"></el-input>
				</div>
				<div class="u-f-item input-box" v-if="Step==2||Step==3">
					<div class="input-lebal">
						<span>*</span>
						新密码
					</div>
					<el-input v-model="passwords" placeholder="请输入新密码" style="width: 70%;"></el-input>
				</div>
				<div class="u-f-item input-box" v-if="Step==3">
					<div class="input-lebal">
						<span>*</span>
						验证码
					</div>
					<div class="u-f-item" style="width: 70%;">
						<el-input v-model="code" placeholder="请输入验证码" style="margin-right: 5px;"></el-input>
						<el-button type="primary" @click="sendCode" v-if="verification">发送验证码</el-button>
						<el-button type="primary" disabled v-if="!verification">{{ timer }} 秒后重新获取</el-button>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="upPad = false" size="small" v-if="Step==0">取 消</el-button>
				<el-button @click="back" size="small" v-if="Step!=0">上一步</el-button>
				<el-button type="primary" size="small" v-if="Step==0||Step==1" @click="next">下一步</el-button>
				<el-button type="primary" size="small" v-if="Step==2||Step==3" @click="tijiao">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import base from '../../api/base.js';
	export default {
		data() {
			return {
				img_url: base.imgUrl,
				account:'',
				password:'',
				upPad:false,
				Step:0,
				mobile:'',
				problem1:'',
				problem2:'',
				radio:'1',
				passwords:'',
				answer1:'',
				answer2:'',
				code:'',
				verification: true, // 通过v-show控制显示获取还是倒计时
				timer: 60
			}
		},
		mounted() {
			window.localStorage.removeItem('meunIndex')
		},
		methods:{
			submit(){
				if(this.account==''||!this.account){return this.$message.error('请输入账号')}
				if(this.password==''||!this.password){return this.$message.error('请输入密码')}
				this.$api.login.login({
					account:this.account,
					password:this.password
				}).then(res=>{
					if(res.data.code==1){
						this.$message.success('登录成功');
						this.$store.commit('settoken',res.data.data.userinfo.token);
						this.$store.commit('nickname',res.data.data.userinfo.nickname);
						this.$store.commit('avatar',res.data.data.userinfo.avatar);
						window.localStorage.setItem('mobile',res.data.data.userinfo.mobile);
						window.localStorage.setItem("id",res.data.data.userinfo.id);
						window.localStorage.setItem('campusid',res.data.data.userinfo.school_campus_id);
						window.localStorage.setItem('year_id',res.data.data.semester.year_id);
						window.localStorage.setItem('semester_id',res.data.data.semester.semester_id);
						this.$router.push('/index');
					}else{
						this.$message.error(res.data.msg)
					}
				})
			},
			handleClose(done){
				done()
			},
			next(){
				if(this.Step==0){
					if(this.mobile==''||!this.mobile){return this.$message.error('请输入手机号码')}
					this.getProblem()
				}else if(this.Step==1){
					console.log(this.radio)
					if(this.radio=='1'){
						console.log(this.radio)
						if(this.problem1==''||!this.problem1){
							this.$message.error('您当前未设置密保问题，请选择其它找回方式')
						}else{
							this.Step= 2;
						}
					}else if(this.radio=='2'){
						this.Step= 3;
					}
				}
			},
			getProblem(){
				this.$api.setting.getProblem({
					mobile:this.mobile
				}).then(res=>{
					if(res.data.code==1){
						this.problem1 = res.data.data.problem1;
						this.problem2 = res.data.data.problem2;
						this.Step = 1;
					}else{
						this.$message.error(res.data.msg)
					}
				})
			},
			back(){
				this.Step--;
			},
			tijiao(){
				let data = {
					mobile:this.mobile,
					password:this.passwords
				};
				if(this.Step==2){
					data.type = 0;
					data.answer1 = this.answer1;
					data.answer2 = this.answer2;
				}else if(this.Step==3){
					data.type = 1;
					data.code = this.code;
				}
				this.$api.setting.findPswd(data).then(res=>{
					if(res.data.code==1){
						this.$message.success('密码重置成功')
						this.upPad = false;
					}else{
						this.$message.error(res.data.msg)
					}
				})
			},
			// 验证码倒计时
			countDown() {
				let auth_timer = setInterval(() => {
					// 定时器设置每秒递减
					this.timer--; // 递减时间
					if (this.timer <= 0) {
						this.verification = true; // 60s时间结束还原v-show状态并清除定时器
						clearInterval(auth_timer);
					}
				}, 1000);
			},
			sendCode(){
				this.$api.setting.getSms({
					mobile:this.mobile
				}).then(res=>{
					if(res.data.code==1){
						this.timer = 60;
						this.verification = false;
						this.$message.success('发送成功')
						this.countDown();
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	#login{
		background-image: url(../../assets/image/bg.png);
		// background-size: 100%;
		background-repeat: no-repeat;
		width: 100vw;
		height: 100vh;
		.login-box{
			margin-top: 20px;
			padding:60px 80px;
			width: 260px;
			border-radius: 10px;
			background-color: #FFFFFF;
			.el-input__inner{
				height: 50px;
				line-height: 50px;
			}
		}
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;
			.el-dialog__title {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 0 20px 35px 20px;
			.edit-box {
				.input-box {
					margin-top: 35px;
					padding-left: 15px;
					.input-lebal {
						width: 20%;
						text-align: right;
						color: #aeb5b8;
						margin-right: 10px;
						span {
							color: #ff7a65;
							margin-right: 3px;
						}
					}
					.el-icon-remove-outline {
						font-size: 1.5625vw;
						color: #d7d9db;
						margin-left: 10px;
					}
				}
				.upload-file{
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					cursor: pointer;
				}
				.avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}
				.avatar-uploader .el-upload:hover {
					border-color: #409eff;
				}
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 90px;
					height: 90px;
					line-height: 90px;
					text-align: center;
				}
				.avatar {
					width: 90px;
					height: 90px;
					display: block;
				}
			}
		}
		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
	}
</style>
