import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块


const pepople = {
	// 角色列表    I
	roleList(params) {
		return axios.get(`${base.sq}/group/index`,{
			params:params
		});
	},
	// 权限列表
	ruleList() {
		return axios.get(`${base.sq}/rule/node`);
	},
	// 老师列表
	teacherList(params) {
		return axios.get(`${base.sq}/teacher/panel`,{
			params:params
		});
	},
	// 添加角色
	addGroup(params) {
		return axios.post(`${base.sq}/group/add`, qs.stringify(params));
	},
	// 编辑角色
	editGroup(params) {
		return axios.post(`${base.sq}/group/edit`, qs.stringify(params));
	},
	// 角色权限设置
	setGroupRule(params) {
		return axios.post(`${base.sq}/group/rule`, qs.stringify(params));
	},
	// 晨午检列表
	coursePatrolList(params) {
		return axios.get(`${base.sq}/course_patrol/index`,{
			params:params
		});
	},
	// 添加巡课
	addCoursePatrol(params) {
		return axios.post(`${base.sq}/course_patrol/add`, qs.stringify(params));
	},
	
	
}

export default pepople;