import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const setting = {
	// 学校信息    I
	schoolInfo() {
		return axios.get(`${base.sq}/setting/school_info`);
	},
	// 学科首页    
	subjectIndex(params) {
		return axios.post(`${base.sq}/school_discipline/index`, qs.stringify(params));
	},
	// 学区学科列表    
	subjectList(params) {
		return axios.get(`${base.sq}/school_discipline/get_school_discipline`, {
			params:params
		});
	},
	// 编辑学校
	editSchool(params) {
		return axios.post(`${base.sq}/setting/school_info`, qs.stringify(params));
	},
	// 校区管理列表
	schoolCampusIndex() {
		return axios.get(`${base.sq}/school_campus/index`);
	},
	studentList(params) {
		return axios.get(`${base.sq}/grade_class/student_lists`,{
			params:params
		});
	},
	// 添加校区
	addCampus(params) {
		return axios.post(`${base.sq}/school_campus/add`, qs.stringify(params));
	},
	// 编辑校区
	editCampus(params) {
		return axios.post(`${base.sq}/school_campus/edit`, qs.stringify(params));
	},
	// 删除校区
	delCampus(params) {
		return axios.post(`${base.sq}/school_campus/del`, qs.stringify(params));
	},
	// 添加学科
	addSubject(params) {
		return axios.post(`${base.sq}/school_discipline/add`, qs.stringify(params));
	},
	// 编辑学科
	editSubject(params) {
		return axios.post(`${base.sq}/school_discipline/edit`, qs.stringify(params));
	},
	// 删除学科
	delSubject(params) {
		return axios.post(`${base.sq}/school_discipline/del`, qs.stringify(params));
	},
	// 添加学科到校区
	addDisciplineCampus(params) {
		return axios.post(`${base.sq}/school_discipline/add_discipline_campus`, qs.stringify(params));
	},
	//人员列表
	userList(params) {
		return axios.get(`${base.sq}/teacher/index`,{
			params:params
		});
	},
	//人员列表
	users(params) {
		return axios.get(`${base.sq}/user/list`,{
			params:params
		});
	},
	// 部门负责人
	Userlead(params) {
		return axios.post(`${base.sq}/school_department/lead`, qs.stringify(params));
	},
	// 科组负责人
	disciplinelead(params) {
		return axios.post(`${base.sq}/school_discipline/lead`, qs.stringify(params));
	},
	// 添加人员
	addUser(params) {
		return axios.post(`${base.sq}/teacher/add`, qs.stringify(params));
	},
	// 编辑人员
	editUser(params){
		return axios.post(`${base.sq}/teacher/edit`, qs.stringify(params));
	},
	// 删除人员
	delUser(params){
		return axios.post(`${base.sq}/teacher/del`, qs.stringify(params));
	},
	// 重置密码
	resetpwd(params){
		return axios.post(`${base.sq}/user/resetpwd`, qs.stringify(params));
	},
	// 禁用用户
	dimissiontime(params){
		return axios.post(`${base.sq}/user/dimissiontime`, qs.stringify(params));
	},
	// 用户解禁
	relieve(params){
		return axios.post(`${base.sq}/user/relieve`, qs.stringify(params));
	},
	// 导入
	teacherImport(params){
		return axios.post(`${base.sq}/teacher/import`, qs.stringify(params));
	},
	// 导出
	teacherDerive(params){
		return axios.get(`${base.sq}/teacher/derive`, {
			params:params
		});
	},
	// 部门列表
	sectionList(params) {
		return axios.post(`${base.sq}/school_department/index`,qs.stringify(params));
	},
	// 添加部门
	sectionAdd(params) {
		return axios.post(`${base.sq}/school_department/add`,qs.stringify(params));
	},
	// 修改部门
	sectionEdit(params) {
		return axios.post(`${base.sq}/school_department/edit`,qs.stringify(params));
	},
	// 删除部门
	sectionDel(params) {
		return axios.post(`${base.sq}/school_department/delete`,qs.stringify(params));
	},
	// 课程时段列表
	courseTimeList(params){
		return axios.get(`${base.sq}/curriculum_time/index`,{
			params:params
		});
	},
	// 添加时段
	addCourseTime(params){
		return axios.post(`${base.sq}/curriculum_time/add`, qs.stringify(params));
	},
	// 编辑时段
	editCourseTime(params){
		return axios.post(`${base.sq}/curriculum_time/edit`, qs.stringify(params));
	},
	// 删除时段
	delCourseTime(params){
		return axios.post(`${base.sq}/curriculum_time/del`, qs.stringify(params));
	},
	// 添加课程时段
	addCurriculumTime(params){
		return axios.post(`${base.sq}/curriculum_time/add_curriculum_time`, qs.stringify(params));
	},
	// 编辑课程时段
	editCurriculumTime(params){
		return axios.post(`${base.sq}/curriculum_time/edit_curriculum_time`, qs.stringify(params));
	},
	getCurriculumTime(params){
		return axios.get(`${base.sq}/curriculum_time/index_curriculum_time`, {
			params:params
		});
	},
	// 删除课程时段
	delCurriculumTime(params){
		return axios.post(`${base.sq}/curriculum_time/del_curriculum_time`, qs.stringify(params));
	},
	// 获取场地列表
	getPlaceList(params){
		return axios.get(`${base.sq}/school_place/index`, {
			params:params
		});
	},
	// 添加场地
	addPlace(params){
		return axios.post(`${base.sq}/school_place/add`, qs.stringify(params));
	},
	// 编辑场地
	editPlace(params){
		return axios.post(`${base.sq}/school_place/edit`, qs.stringify(params));
	},
	// 删除场地
	delPlace(params){
		return axios.post(`${base.sq}/school_place/del`, qs.stringify(params));
	},
	// 获取楼层列表
	getfloor(params){
		return axios.get(`${base.sq}/school_place_floor/get_floor`,{
			params:params
		});
	},
	// 编辑楼层
	editFloor(params){
		return axios.post(`${base.sq}/school_place_floor/edit`, qs.stringify(params));
	},
	// 添加楼层
	addFloor(params){
		return axios.post(`${base.sq}/school_place_floor/add`, qs.stringify(params));
	},
	// 删除楼层
	delFloor(params){
		return axios.post(`${base.sq}/school_place_floor/del`, qs.stringify(params));
	},
	// 获取房间列表
	getPlaceRoomList(params){
		return axios.get(`${base.sq}/school_place_room/index`, {params:params});
	},
	// 添加房间
	addRoom(params){
		return axios.post(`${base.sq}/school_place_room/add`, qs.stringify(params));
	},
	// 编辑房间
	editRoom(params){
		return axios.post(`${base.sq}/school_place_room/edit`, qs.stringify(params));
	},
	// 删除房间
	delRoom(params){
		return axios.post(`${base.sq}/school_place_room/del`, qs.stringify(params));
	},
	// 年级管理
	getGradeList(params){
		return axios.get(`${base.sq}/grade/index`, {
			params:params
		});
	},
	// 添加年级
	addGrade(params){
		return axios.post(`${base.sq}/grade/add`, params);
	},
	// 编辑年级
	editGrade(params){
		return axios.post(`${base.sq}/grade/edit`, params);
	},
	// 删除年级
	delGrade(params){
		return axios.post(`${base.sq}/grade/del`, qs.stringify(params));
	},
	// 班级层次管理
	getLevelList(params){
		return axios.get(`${base.sq}/grade_level/index`, {
			params:params
		});
	},
	// 添加班级层次
	addLevel(params){
		return axios.post(`${base.sq}/grade_level/add`, params);
	},
	// 编辑班级层次
	editLevel(params){
		return axios.post(`${base.sq}/grade_level/edit`, params);
	},
	// 删除班级层次
	delLevel(params){
		return axios.post(`${base.sq}/grade_level/del`, qs.stringify(params));
	},
	// 班级管理
	getClassList(params){
		return axios.get(`${base.sq}/grade_class/index`, {
			params:params
		});
	},
	// 添加班级
	addClass(params){
		return axios.post(`${base.sq}/grade_class/add`, params);
	},
	// 编辑班级
	editClass(params){
		return axios.post(`${base.sq}/grade_class/edit`, params);
	},
	// 删除班级
	delClass(params){
		return axios.post(`${base.sq}/grade_class/del`, qs.stringify(params));
	},
	// 班级学生名单列表
	classStudent(params){
		return axios.post(`${base.sq}/grade_class/student_list`, qs.stringify(params));
	},
	// 获取学年学期
	getSemesterList(params){
		return axios.get(`${base.sq}/semester/index`, {
			params:params
		});
	},
	// 添加学年
	addSemester(params){
		return axios.post(`${base.sq}/semester/add`, qs.stringify(params));
	},
	// 编辑学年
	editSemester(params){
		return axios.post(`${base.sq}/semester/edit`, qs.stringify(params));
	},
	// 删除学年
	delSemester(params){
		return axios.post(`${base.sq}/semester/del`, qs.stringify(params));
	},
	// 获取学期列表
	getXueQiList(params){
		return axios.get(`${base.sq}/semester/semesters`, {
			params:params
		});
	},
	// 添加学期
	addXueQi(params){
		return axios.post(`${base.sq}/semester/add_semester`, qs.stringify(params));
	},
	// 编辑学期
	editXueQi(params){
		return axios.post(`${base.sq}/semester/edit_semester`, qs.stringify(params));
	},
	// 删除学期
	delXueQi(params){
		return axios.post(`${base.sq}/semester/del`, qs.stringify(params));
	},
	// 设置默认值
	setDefault(params){
		return axios.post(`${base.sq}/semester/set_default`, qs.stringify(params));
	},
	// 评价配置列表
	evaluationConfigList(params){
		return axios.get(`${base.sq}/evaluation_config/index`, {
			params:params
		});
	},
	// 添加配置
	addConfog(params){
		return axios.post(`${base.sq}/evaluation_config/add`, qs.stringify(params));
	},
	// 删除配置
	delConfog(params){
		return axios.post(`${base.sq}/evaluation_config/del`, qs.stringify(params));
	},
	// 获取可用评价配置
	getCategory(params){
		return axios.get(`${base.sq}/evaluation_config/get_category`, {
			params:params
		});
	},
	// 年级学科课时列表
	gradeCourseHour(params){
		return axios.get(`${base.sq}/grade_course_hour/index`, {
			params:params
		});
	},
	// 添加年级学科课时
	addCourseHour(params){
		return axios.post(`${base.sq}/grade_course_hour/add`, qs.stringify(params));
	},
	// 文件上传
	uploadFile(params){
		return axios.post(`${base.sq}/Common/upload`, params);
	},
	// 首页快捷入口
	getUserRule(params){
		return axios.get(`${base.sq}/rule/get_user_rule`, {
			params:params
		});
	},
	// 获取学周
	getweekList(params){
		return axios.get(`${base.sq}/semester/week`, {
			params:params
		});
	},
	// 消息通知
	getSystemNotice(params){
		return axios.get(`${base.sq}/system_notice/index`, {
			params:params
		});
	},
	// 设置常用菜单
	setuserRule(params){
		return axios.post(`${base.sq}/rule/user_rule`,qs.stringify(params));
	},
	// 宿舍楼管理
	dormitoryList(params){
		return axios.post(`${base.sq}/Dormitory/dormitoryList`,qs.stringify(params));
	},
	// 添加宿舍楼
	addDormitory(params){
		return axios.post(`${base.sq}/Dormitory/add`,qs.stringify(params));
	},
	// 编辑宿舍楼
	editDormitory(params){
		return axios.post(`${base.sq}/Dormitory/edit`,qs.stringify(params));
	},
	// 删除宿舍楼
	delDormitory(params){
		return axios.post(`${base.sq}/Dormitory/del`,qs.stringify(params));
	},
	// 宿舍列表
	dormindex(params){
		return axios.get(`${base.sq}/Dorm/index`,{params:params});
	},
	// 添加宿舍
	addDorm(params){
		return axios.post(`${base.sq}/Dorm/add`,qs.stringify(params));
	},
	// 编辑宿舍
	editDorm(params){
		return axios.post(`${base.sq}/Dorm/edit`,qs.stringify(params));
	},
	// 删除宿舍楼
	delDorm(params){
		return axios.post(`${base.sq}/Dorm/del`,qs.stringify(params));
	},
	// 可分配的宿舍列表
	bindableList(params){
		return axios.get(`${base.sq}/SupervisorDorm/bindable`,{params:params});
	},
	// 宿管列表
	SupervisorDormList(params){
		return axios.get(`${base.sq}/SupervisorDorm/index`,{params:params});
	},
	// 添加宿管
	bindingDormitory(params){
		return axios.post(`${base.sq}/SupervisorDorm/bindingDormitory`,qs.stringify(params));
	},
	// 住宿生列表
	bedList(params){
		return axios.get(`${base.sq}/bed/bed_list`,{params:params});
	},
	// 获取床位
	bedIndex(params){
		return axios.get(`${base.sq}/bed/index`,{params:params});
	},
	// 宿舍安排
	bedbind(params){
		return axios.post(`${base.sq}/Bed/bind`,qs.stringify(params));
	},
	// 添加校历
	addSchoolCourse(params){
		return axios.post(`${base.sq}/school_course/add`,qs.stringify(params));
	},
	// 获取学期校历
	getSchoolCourseList(params){
		return axios.get(`${base.sq}/school_course/list`,{params:params});
	},
	// 获取当前校历事件
	getSchoolCourseDetail(params){
		return axios.get(`${base.sq}/school_course/detail`,{params:params});
	},
	// 通知消息已读
	readMsg(params){
		return axios.post(`${base.sq}/system_notice/read`,qs.stringify(params));
	},
	// 上传
	upload(params){
		return axios.post(`${base.sq}/Common/upload`,qs.stringify(params));
	},
	// 宿舍导入
	bedImport(params){
		return axios.post(`${base.sq}/bed/import`,params);
	},
	// 学生导入
	bindImport(params){
		return axios.post(`${base.sq}/bed/bind_import`,params);
	},
	// 奖惩列表
	rewardLists(params){
		return axios.get(`${base.sq}/RewardsRecord/rewards_pc`,{params:params});
	},
	protection(params){
		return axios.post(`${base.sq}/teacher/protection`,qs.stringify(params));
	},
	// 奖惩导出
	rewardsImport(params){
		return axios.post(`${base.sq}/RewardsRecord/rewards_import`,qs.stringify(params));
	},
	// 获取用户密保问题
	getProblem(params){
		return axios.post(`${base.sq}/teacher/getProblem`,qs.stringify(params));
	},
	updatePad(params){
		return axios.post(`${base.sq}/user/repassword`,qs.stringify(params));
	},
	getSms(params){
		return axios.post(`${base.sq}/teacher/getSms`,qs.stringify(params));
	},
	findPswd(params){
		return axios.post(`${base.sq}/teacher/findPswd`,qs.stringify(params));
	},
	// 获取奖惩分类列表
	rewardsTypeIndex(params){
		return axios.get(`${base.sq}/rewards_type/index`,{params:params});
	},
	// 添加奖惩分类
	addRewardsType(params){
		return axios.post(`${base.sq}/rewards_type/add`,qs.stringify(params));
	},
	// 删除奖惩分类
	delRewardsType(params){
		return axios.post(`${base.sq}/rewards_type/del`,qs.stringify(params));
	},
	// 编辑奖惩分类
	editRewardsType(params){
		return axios.post(`${base.sq}/rewards_type/edit`,qs.stringify(params));
	},
	// 添加奖惩配置
	addRewardsConfig(params){
		return axios.post(`${base.sq}/rewards_config/add`,qs.stringify(params));
	},
	// 删除奖惩配置
	delRewardsConfig(params){
		return axios.post(`${base.sq}/rewards_config/del`,qs.stringify(params));
	},
	// 编辑奖惩配置
	editRewardsConfig(params){
		return axios.post(`${base.sq}/rewards_config/edit`,qs.stringify(params));
	},
	// 奖惩配置
	RewardsConfigIndex(params){
		return axios.get(`${base.sq}/rewards_config/index`,{params:params});
	},
	// 添加小组评分
	submitsScore(params){
		return axios.post(`${base.sq}/team/submits`,qs.stringify(params));
	},
	// 小组列表
	teamList(params){
		return axios.post(`${base.sq}/team/lists`,qs.stringify(params));
	},
	// 删除小组列表
	teamdel(params){
		return axios.post(`${base.sq}/team/dels`,qs.stringify(params));
	},
	// 编辑小组列表
	teamedit(params){
		return axios.post(`${base.sq}/team/modify`,qs.stringify(params));
	},
	teamexports(params){
		return axios.post(`${base.sq}/team/exports`,qs.stringify(params));
	},
	// 年级得分统计
	gradeCensus(params){
		return axios.post(`${base.sq}/team/gradeCensus`,qs.stringify(params));
	},
	// 班级得分统计
	classCensus(params){
		return axios.post(`${base.sq}/team/classCensus`,qs.stringify(params));
	},
	// 学科得分统计
	subjectCensus(params){
		return axios.post(`${base.sq}/team/subjectCensus`,qs.stringify(params));
	},
	// 小组得分统计
	smallCensus(params){
		return axios.post(`${base.sq}/team/smallCensus`,qs.stringify(params));
	},
	// 约谈记录
	tutorChecks(params){
		return axios.post(`${base.sq}/tutor/checks`,qs.stringify(params));
	},
	// 导师得分列表
	tutorScoreList(params){
		return axios.post(`${base.sq}/tutor/tutorScoreList`,qs.stringify(params));
	},
	// 导师列表
	tutorList(params){
		return axios.post(`${base.sq}/tutor/getTutor`,qs.stringify(params));
	},
	// 添加导师得分
	scoreOpera(params){
		return axios.post(`${base.sq}/tutor/scoreOpera`,qs.stringify(params));
	},
	// 修改导师得分
	tutorModify(params){
		return axios.post(`${base.sq}/tutor/tutorModify`,qs.stringify(params));
	},
	// 删除导师得分
	tutorDels(params){
		return axios.post(`${base.sq}/tutor/tutorDels`,qs.stringify(params));
	},
	// 导师小组人员
	modifyList(params){
		return axios.post(`${base.sq}/tutor/modifyList`,qs.stringify(params));
	},
	// 添加小组人员
	modifyAdd(params){
		return axios.post(`${base.sq}/tutor/modifyAdd`,qs.stringify(params));
	},
	// 添加小组人员
	modifyedit(params){
		return axios.post(`${base.sq}/tutor/modify`,qs.stringify(params));
	},
	modifydels(params){
		return axios.post(`${base.sq}/tutor/dels`,qs.stringify(params));
	},
	modifyimport(params){
		return axios.post(`${base.sq}/tutor/import`,qs.stringify(params));
	},
	// 详情
	tutorDetails(params){
		return axios.post(`${base.sq}/tutor/details`,params);
	},
	checkOpera(params){
		return axios.post(`${base.sq}/tutor/checkOpera`,params);
	},
	// 导出谈心辅导
	exportsHeart(params){
		return axios.post(`${base.sq}/tutor/exportsHeart`,params);
	},
	// 导师阶段性自评列表
	stageList(params){
		return axios.post(`${base.sq}/tutor/stageList`,params);
	},
	// 导师阶段性自评导出
	exportsstage(params){
		return axios.post(`${base.sq}/tutor/exports`,params);
	},
	// 导入
	importScore(params){
		return axios.post(`${base.sq}/tutor/importScore`,params);
	},
	// 添加小组账号
	addOperator(params){
		return axios.post(`${base.sq}/team/addOperator`,params);
	},
	getlistsOperator(params){
		return axios.post(`${base.sq}/team/listsOperator`,params);
	},
	editOperator(params){
		return axios.post(`${base.sq}/team/modifyOperator`,params);
	},
	teamdelUser(params){
		return axios.post(`${base.sq}/team/delsOperator`,params);
	},
	RewardsRecordModify(params){
		return axios.post(`${base.sq}/RewardsRecord/modify`,params);
	},
	tutorSetExport(params){
		return axios.post(`${base.sq}/export/exports1`,params);
	},
	tutorScoreExport(params){
		return axios.post(`${base.sq}/export/exports2`,params);
	},
	studentrewardsExport(params){
		return axios.post(`${base.sq}/export/exports3`,params);
	},
	classRewardsExport(params){
		return axios.post(`${base.sq}/export/exports4`,params);
	},
	dormRewardsExport(params){
		return axios.post(`${base.sq}/export/exports5`,params);
	},
	coursePatrolderive(params){
		return axios.get(`${base.sq}/course_patrol/derive`,{params:params});
	},
	deployExport(params){
		return axios.post(`${base.sq}/export/exports6`,params);
	},
	deployimport(params){
		return axios.post(`${base.sq}/export/import1`, qs.stringify(params));
	},
	// 资讯分类列表
	ArticleClassList(params){
		return axios.post(`${base.sq}/ArticleClass/lists`, qs.stringify(params));
	},
	// 添加咨询分类
	addArticleClass(params){
		return axios.post(`${base.sq}/ArticleClass/add`, qs.stringify(params));
	},
	// 编辑咨询分类
	editArticleClass(params){
		return axios.post(`${base.sq}/ArticleClass/modify`, qs.stringify(params));
	},
	// 删除咨询分类
	delArticleClass(params){
		return axios.post(`${base.sq}/ArticleClass/dels`, qs.stringify(params));
	},
	// 获取资讯列表
	getArticleList(params){
		return axios.post(`${base.sq}/Article/lists`, qs.stringify(params));
	},
	// 获取资讯分类列表
	getArticleClassList(params){
		return axios.post(`${base.sq}/Article/getClass`, qs.stringify(params));
	},
	// 添加资讯
	addArticle(params){
		return axios.post(`${base.sq}/Article/add`, qs.stringify(params));
	},
	// 删除资讯
	delArticle(params){
		return axios.post(`${base.sq}/Article/dels`, qs.stringify(params));
	},
	// 资讯详情
	ArticleDetails(params){
		return axios.post(`${base.sq}/Article/details`, qs.stringify(params));
	},
	// 添加轮播图
	addBanner(params){
		return axios.post(`${base.sq}/pic/add`, qs.stringify(params));
	},
	// 修改轮播图
	editBanner(params){
		return axios.post(`${base.sq}/pic/modify`, qs.stringify(params));
	},
	// 删除轮播图
	delBanner(params){
		return axios.post(`${base.sq}/pic/dels`, qs.stringify(params));
	},
	// 轮播图列表
	getBannerList(params){
		return axios.post(`${base.sq}/pic/lists`, qs.stringify(params));
	},
	// 个人单位导出
	exports8(params){
		return axios.post(`${base.sq}/export/exports8`, qs.stringify(params));
	},
	// 班级单位导出
	exports9(params){
		return axios.post(`${base.sq}/export/exports9`, qs.stringify(params));
	},
	// 宿舍单位导出
	exports12(params){
		return axios.post(`${base.sq}/export/exports12`, qs.stringify(params));
	},
	// 学生预警提示
	exports15(params){
		return axios.post(`${base.sq}/export/exports15`, qs.stringify(params));
	},
	// 班级预警提示
	exports16(params){
		return axios.post(`${base.sq}/export/exports16`, qs.stringify(params));
	},
	// 宿舍预警提示
	exports17(params){
		return axios.post(`${base.sq}/export/exports17`, qs.stringify(params));
	},
	// 编辑学生宿舍
	changeBed(params){
		return axios.post(`${base.sq}/Bed/change`, qs.stringify(params));
	},
	// 导出学生宿舍
	exports18(params){
		return axios.post(`${base.sq}/export/exports18`, qs.stringify(params));
	},
	// 导出嘉奖
	exports19(params){
		return axios.post(`${base.sq}/export/exports19`, qs.stringify(params));
	},
	// 导入嘉奖
	import19(params){
		return axios.post(`${base.sq}/export/import19`, qs.stringify(params));
	},
	// 奖惩录入
	checkRegistration(params){
		return axios.post(`${base.sq}/RewardsRecord/checkRegistration`, qs.stringify(params));
	},
	
}

export default setting;
