import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const asset = {
	// 资产分类列表
	getAssetTypeList(params){
		return axios.get(`${base.sq}/property_category/index`,{params:params});
	},
	// 添加分类
	addAssetType(params){
		return axios.post(`${base.sq}/property_category/add`,qs.stringify(params));
	},
	// 编辑分类
	editAssetType(params){
		return axios.post(`${base.sq}/property_category/edit`,qs.stringify(params));
	},
	// 删除分类
	delAssetType(params){
		return axios.post(`${base.sq}/property_category/del`,qs.stringify(params));
	},
	// 资产列表
	getAssetList(params){
		return axios.get(`${base.sq}/property_warehouse/index`,{params:params});
	},
	// 资产入库
	assetStroage(params){
		return axios.post(`${base.sq}/property_warehouse/add`,qs.stringify(params));
	},
	// 资产编辑
	assetEdit(params){
		return axios.post(`${base.sq}/property_warehouse/edit`,qs.stringify(params));
	},
	// 物品领用
	receive(params){
		return axios.post(`${base.sq}/property_borrow/add`,qs.stringify(params));
	},
	
}
export default asset;