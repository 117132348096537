/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import router from '../router';
import store from '../store/index';
import { Message } from 'element-ui';


/** 
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
	router.replace({
		path: '/',
		query: {
			redirect: router.currentRoute.fullPath
		}
	});
}

/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (code, other) => {
	console.log(code)
	// 状态码判断
	switch (code) {
		// 401: 未登录状态，跳转登录页
		case 401:
			Message.error('您还未登录，请先登录');
			toLogin();
			break;
			// 403 token过期
			// 清除token并跳转登录页
		case 403:
			Message.error('您没有权限此操作，请联系管理员');
			break;
			// 404请求不存在
		case 404:
			Message.error('请求的资源不存在');
			break;
		default:
			console.log(other);
	}
}

// 创建axios实例
var instance = axios.create({
	timeout: 1000 * 12
});
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/** 
 * 请求拦截器 
 * 每次请求前，如果存在token则在请求头中携带token 
 */
instance.interceptors.request.use(
	config => {
		// 登录流程控制中，根据本地是否存在token判断用户的登录情况        
		// 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
		// 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
		// 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。        
		const token = window.localStorage.getItem("token");
		const campusid = window.localStorage.getItem("campusid");
		token && (config.headers.token = token);
		campusid && (config.headers.campusid = campusid);
		return config;
	},
	error => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    Message.error('您还未登录，请先登录');
					localStorage.clear();
                    setTimeout(() => {
                        router.replace({
                            path: '/',
                        });
                    }, 1000);
                    break;
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 403:
                    Message.err('您没有权限此操作，请联系管理员');
                    break;
                // 404请求不存在
                case 404:
                    // Toast.Message.error('网络请求不存在');
                break;
                // 其他错误，直接抛出错误提示
                default:
                    console.log(error.response.data.msg)
                    // Toast.Message.error(error.response.data.msg);
            }
            return Promise.reject(error.response);
        }
    }
);

export default instance
