/**
 * login模块接口列表
 */

import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const login = {    
    // // 新闻列表    
    // articleList () {        
    //     return axios.get(`${base.sq}/topics`);    
    // },    
    // // 新闻详情,演示    
    // articleDetail (id, params) {        
    //     return axios.get(`${base.sq}/topic/${id}`, {            
    //         params: params        
    //     });    
    // },
    // post提交    
    login (params) {        
        return axios.post(`${base.sq}/user/login`, qs.stringify(params));    
    },
    // 获取地址
    getAreaList (params) {        
        return axios.post(`${base.sq}/area/getAreaList`, qs.stringify(params));    
    },
    // 文件上传
    uploadFile (params) {        
        return axios.post(`${base.sq}/common/upload`, params);    
    },
	// 我的权限
	userRules() {
		return axios.get(`${base.sq}/user/rules`);
	},
	
}

export default login;