import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const file = {
	// 获取公文列表
	getDocumentIndex(params){
		return axios.get(`${base.sq}/document/index`, {
			params: params
		});
	},
	// 添加文件
	addDocument(params){
		return axios.post(`${base.sq}/document/add`, qs.stringify(params));
	},
	// 我的公文列表
	getMyDocumentList(params){
		return axios.get(`${base.sq}/document/my_list`, {
			params: params
		});
	},
	// 我的审批
	getMyProcess(params){
		return axios.get(`${base.sq}/document/my_process`, {
			params: params
		});
	},
	// 添加分类
	addCategory(params){
		return axios.post(`${base.sq}/document/add_ccategory`, qs.stringify(params));
	},
	// 编辑分类
	editCategory(params){
		return axios.post(`${base.sq}/document/edit_ccategory`, qs.stringify(params));
	},
	// 删除分类
	delCategory(params){
		return axios.post(`${base.sq}/document/del_ccategory`, qs.stringify(params));
	},
	// 分类列表
	getCategoryList(params){
		return axios.get(`${base.sq}/document/index_category`, {
			params: params
		});
	},
	// 下发文件
	issueFile(params){
		return axios.post(`${base.sq}/document/add`, qs.stringify(params));
	},
	// 已阅
	readFile(params){
		return axios.post(`${base.sq}/document/read`, qs.stringify(params));
	}
	
}

export default file;